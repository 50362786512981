import React from 'react';
import '../css/Layout.css';
import {Link} from 'react-router-dom';

function LayoutSearch(props) {
  // const[page, ChangePage] = useState({
  //   headTitle:"查詢卡卡城紅利",
  //   linkUri: "https://cacacity.tw"
  // });

  // const ChangePage = (props) => {
  //     props.page.headTitle = "查詢卡卡城遊戲";
  //     props.page.linkUri = "https://dividend.cacacity.tw/"
  // }

  return (
    <div className="App">

      <header className="App-header">
        {props.children}
      </header>
    </div>
  );
}

export default LayoutSearch;
