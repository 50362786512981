import styled from 'styled-components';

const ProductContainerStyle = styled.div`
    display: flex;
    flex-flow: column wrap;
    margin: 1px;
    padding: 0px;
    font-size:x-small;
    color: ${props => props.theme.color};
    .productRow{
        margin: 1px;
        padding: 0px;
        box-sizing: border-box;
        width:100%;
        max-width:1000px;
        display: -webkit-flex;
        display:flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;

        @media (max-width: 400px) {
            .product {
                width: 100%;
            }
        }
    }
`

ProductContainerStyle.defaultProps = {
    theme: {
        color: "black"
    }
}

export default ProductContainerStyle;