import styled from 'styled-components';

const ProductStyle = styled.div`
    background: #FEFEFE;
    border-radius: 2px;
    box-shadow: 0 1px 2px 1px rgb(0 0 0 / 12%);
    margin: 2px;
    height:250px;
    padding: 0px;
    display: flex;
    flex-flow: row wrap;
    flex: 1 0 49%;
    top: 2px;
    overflow: visible;
    color: #000000;
`

ProductStyle.defaultProps = {
    theme: {
        color: "black"
    }
}

export default ProductStyle;