import React from 'react';
import Product from './Product';
/*
dividend search game block list
*/
const ProductRow = (props)=>{
    // const title = props.title;
    // data是一個資料物件
    // gameId, gameName, gamePic, rentStatus, storageStatus
    // const data = props.data;
    const rows = [];
    let i = 0;
    let style = {};
    props.data.forEach((game) => {
        rows.push(
            <Product data = {game} key={game.gid}/>
        );
        i++;
    });

    return(
        <div className="productRow">
            {rows}
        </div>
    )
}

export default ProductRow;