import React from 'react';
import ProductRow from './ProductRow';
/*
dividend search game block list
*/
const ProductContainer = (props) =>{
    const data = props.data;
    return(
        <div className="productContainer">
            <ProductRow data={data}/>
        </div>
    )
}

export default ProductContainer;