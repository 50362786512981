// import React from 'react';

/*
*  使用https required 調用的資料
*/
const UseFetch = (function(){

    return {
        apiCacacity : {
            "dividend": ".././test/api_search_redpoint.php",
            "searchGame": ".././test/api_search_gamename.php",
            "searchForumbb": "http://localhost/index.php/API/search_forumbb",
        }
    }
})();

export default UseFetch;