import styled from 'styled-components';

const ProductInfoStyle = styled.div`
    padding:1em;
    height:vmin;
    width:60%;
    display:block;
    justify-content: center;
    align-items:center;
    font-size:x-small;
    color: #000000;
    gameEName{
        display:block;
        font-size:x-small;
        padding-bottom: 1em;
    }
    li{
        width:100%;
        display:inline;
        float:left;
    }
    hr{
        size:8px;
        align:center;
        width:100%;
    }
`

ProductInfoStyle.defaultProps = {
    theme: {
        color: "black"
    }
}

export default ProductInfoStyle;