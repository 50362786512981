import React, { Component } from 'react';
// import Validator from 'validator';
// import { JsonToTable } from "react-json-to-table";
import 'bootstrap/dist/css/bootstrap.min.css';
// import {Button} from 'react-bootstrap';

class SearchBar extends Component {
  


  render() {

    // 將 value 設定為 this.state.value
    // 並監聽 onChange 來更新 state
    const formStyle = { 
      float:'left',
      width:'100%', 
      fontSize:'2em',
      margin:'1em'
    }
    return (

      <form style = {formStyle} onSubmit={this.props.onSubmit}>
        <input 
          placeholder="請輸入商品名稱"
          type="text" 
          name="gameName" 
          value={this.props.gameName}
          onChange={this.props.onChange}
          ></input>
        <input type="submit" value="搜索" />
      </form>
    );
  }
}
export default SearchBar;