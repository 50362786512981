import React,{Component} from 'react';
// import ListTable from './ListTable';
import UseFetch from '.././UseFetch';
import SearchBar from './SearchBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductContainer from './ProductContainer';
import ProductContainerStyle from './style/ProductContainerStyle';
/*
    查詢卡卡城可以販售的遊戲
    1. 有現貨
    2. 想要推薦給客人的
    3. 現貨特價品    (限時特價)
    4. 開封舊品特價

    查詢卡卡城可以租借的遊戲
    1. 有在店內的
    2. 店長推薦的

    遊戲相關資訊介紹
*/

class FilterableBG extends Component {
    constructor(props) {
        super(props);
        
        // 初始化輸入框的 state 值為空
        this.state = {
          gameName: '',
          showArea:'',
          arrStores : [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // onChange 事件處理函示
    handleChange(event) {
        // event.target 是當前的 DOM elment
        // 從 event.target.value 取得 user 剛輸入的值
        // 將 user 輸入的值更新回 state
        const target = event.target;
        const value = target.value;
        const name = target.name;
        // console.log(value)
        this.setState({
          [name]:value,
          arrStores:[]
        });
    }
  // form onSubmit 事件處理函式
    handleSubmit(event) {
    
        // let iPhone = document.getElementsByName("checkPhone");
        // alert('Submit ' + iphone.value);
        //console.log(this.validatePhoneNumber(this.state.checkPhone))
        // this.setState({
        //     arrStores: []
        // });
        const data = {
            game_name: this.state.gameName,
            page: 0
        }
        const formData = Object.keys(data).map(
          function (keyName) {
            return encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName])
          }
        ).join('&');
        this.setState({showArea :""})

        fetch( UseFetch.apiCacacity.searchGame, {
            method: "POST",
            body: formData,
            // body: JSON.stringify(data),
            // headers: {
            // "Content-Type": "application/json; charset=utf-8",
            // },
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            })
        })
        .then(res => res.json())
        .then(data => {
              /*接到request data後要做的事情*/
            // console.log(data);
            if(data.gbox_json !== undefined){
                let arrGid = [];
                let resData = [];
                arrGid = Object.keys(data.gbox_json)
                //如果有十筆資料出現用更精準的搜尋 如果0筆提示沒有搜索結果
                console.log(arrGid.length);
                if(arrGid.length == 10){
                    this.setState({
                        showArea:"還有更多搜尋結果，請用更精準的關鍵字進行搜尋。"+
                        "例如: \'牌套\'改輸入\'6.5x9\'、\'卡卡\'->\'卡卡頌\'",
                    })
                } else if (arrGid.length == 0){
                    this.setState({
                        showArea:"您所輸入的關鍵字查無資料，建議改其他關鍵字"+
                        "或電洽鄰近門市詢問",
                    })
                }
                //處理每筆資料
                //建立資料表的表頭
                    this.setState({
                      arrStores:[]
                    });
                arrGid.forEach((i)=>{
                // arrGames[i]= data.gbox_json[i]
                    let dataElement = data.gbox_json[i]
                    Object.values(data.gbox_json[i].owner).forEach((val)=>{
                        if(val.store && val.store !== null){
                            resData.push(val.store);
                        }
                    })
                    resData = resData.filter((item,index,arr) => {
                        return arr.indexOf(item) === index;
                    });
                    // console.log(dataElement.storage);
                    //有貨的店
                    const storageStore = Object.keys(dataElement.storage);
                    //移除總公司
                    const index = storageStore.indexOf('總公司');

                    if (index > -1) {
                        storageStore.splice(index, 1);
                    }

                    if (resData==0 && storageStore.length == 0) return;
                    
                    this.setState(
                    {
                        arrStores:  
                            this.state.arrStores.concat([{
                                gameId:i,
                                gameCName: dataElement.cName,
                                gameEName: dataElement.eName,
                                rentStatus:(resData)?Object.values(resData).toString():"",
                                pic:dataElement.pic,
                                storageStatus:( storageStore.length > 0 )? storageStore.toString() : "缺貨",
                                originalPrice: dataElement.originalPrice,
                                memberPrice: dataElement.memberPrice,
                                vipPrice: dataElement.vipPrice,
                            }]),
                        }
                    )
                    

                    resData = [];
                })
            // const myObject = JSON.stringify(arrStores);
          }else{
            this.setState({
                showArea: "輸入資料有誤!"
            });
          }
        })
        .catch(e => {
          console.log(e);
            /*發生錯誤時要做的事情*/
        })

        event.preventDefault();
    }
    render(){
        return(
            <ProductContainerStyle>
            {/**
                //更換表單版型
                <SearchBar onSubmit={this.handleSubmit} onChange={this.handleChange}/>
                <ListTable data={this.state.arrStores}/>
            */}
                <SearchBar onSubmit={this.handleSubmit} onChange={this.handleChange}/>
                <ProductContainer data={this.state.arrStores} />
                <div><h5 sytle={{"color":"red"}}>{this.state.showArea}</h5></div>
            </ProductContainerStyle>
        )
    }
}

export default FilterableBG;