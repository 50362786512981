import React, { Component } from 'react';
import Validator from 'validator';
import UseFetch from './UseFetch';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button} from 'react-bootstrap';

class SearchDividendForm extends Component {
  constructor(props) {
    super(props);
    
    // 初始化輸入框的 state 值為空
    this.state = {
      checkPhone: '',
      checkCard: '',
      showArea:''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validatePhoneNumber = this.validatePhoneNumber.bind(this);

  }

  validatePhoneNumber = (number) => {
     const isValidPhoneNumber = Validator.isMobilePhone(number)
     return (isValidPhoneNumber)
  }

  // onChange 事件處理函示
  handleChange(event) {
    // event.target 是當前的 DOM elment
    // 從 event.target.value 取得 user 剛輸入的值
    // 將 user 輸入的值更新回 state
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]:value
    });
  }

  // form onSubmit 事件處理函式
  handleSubmit(event) {
    
    // let iPhone = document.getElementsByName("checkPhone");
    // alert('Submit ' + iphone.value);
    //console.log(this.validatePhoneNumber(this.state.checkPhone))
    if(!this.validatePhoneNumber(this.state.checkPhone))
      this.setState({checkPhone:""})
    const data = {
      cardno: this.state.checkCard,
      phone: this.state.checkPhone
    }
    const formData = Object.keys(data).map(
      function (keyName) {
        return encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName])
      }
    ).join('&');
    this.setState({showArea :""})

    fetch( UseFetch.apiCacacity.dividend, {
        method: "POST",
        body: formData,
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        })
    })
    .then(res => res.json())
    .then(data => {
          /*接到request data後要做的事情*/
      if(data.RTN && data.COST !== undefined){
        if(data.COST == 0 ){
          if(data.PS != ""){
            var str = "您的紅利已於" + data.PS.substr(0,10) +"清空"
          } else {
            var str = "您紅利為 0"
          }
          this.setState({
            showArea: str
          });
        } else {
          var date1 = new Date(data.L_DATE.substr(0,10));
          var date2 = new Date("2021-09-16");
          if(date1 < date2){
            date1.setFullYear(date1.getFullYear()+1)
            date1.setMonth(date1.getMonth()+1)
          }else{
            date1 = new Date("2022-09-16") 
          }
            var str = "您的紅利即將於" 
            + date1.getFullYear() + "-" 
            + date1.getMonth() + "-"
            + date1.getDate() + "到期";
          this.setState({
            showArea: "您目前剩餘的紅利為" + data.COST
            + "點\n (購買遊戲，每10點可折抵1元) \n"
            + str
          });
        }
      }else{
        this.setState({
            showArea: "輸入資料有誤!"
          });
      }
    })
    .catch(e => {
      console.log(e);
        /*發生錯誤時要做的事情*/
    })

    event.preventDefault();
  }

  render() {
    // 將 value 設定為 this.state.value
    // 並監聽 onChange 來更新 state
    return (
      <form onSubmit={this.handleSubmit}>
        <h1>查詢卡卡城紅利</h1>
        <h6>*請同時輸入會員卡號與會員電話進行查詢*</h6>
        <input 
          placeholder="請輸入卡號"
          type="text" 
          name="checkCard" 
          value={this.state.checkCard}
          onChange={this.handleChange}
          ></input>
        <input 
          placeholder="請輸入電話"
          type="text" 
          name="checkPhone" 
          value={this.state.checkPhone}
          onChange={this.handleChange}
          ></input>
        <Button variant="outline-info" type="submit">查詢</Button>
        <div style={{whiteSpace: 'pre-wrap'}}>{this.state.showArea}</div>
      </form>
    );
  }
}
export default SearchDividendForm;