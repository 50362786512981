import styled from 'styled-components';

const ProductImageStyle = styled.div`
    overflow:hidden;
    height:100%;
    width:40%;
    margin:0;
    padding:0;
    display:flex;
    position: relative;
    color: ${props => props.theme.color};
`
ProductImageStyle.defaultProps = {
    theme: {
        color: "mediumseagreen"
    }
}

export default ProductImageStyle;