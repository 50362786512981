import styled from 'styled-components';

const ImageStyle = styled.img`
    max-height: 100%;  
    max-width: 100%; 
    width: auto;
    height: auto;
    position: absolute;  
    top: 0;  
    bottom: 0;  
    left: 0;  
    right: 0;  
    margin: auto;
    padding:1em;
`

ImageStyle.defaultProps = {
    theme: {
        color: "mediumseagreen"
    }
}

export default ImageStyle;