// import React, {useState} from 'react';
import React from 'react';
// import './css/App.css';
import {HashRouter,Route,Switch} from "react-router-dom";
import LayoutSearch from './components/LayoutSearch'
import SearchDividendForm from './components/SearchDividendForm';
import FilterableBG from './components/SearchBG/FilterableBG';
// import Parent from './components/Parent'
// import Counter from './components/Counter/index'

function App() {
    // const[count,setCount] = useState(0);
  return (
    <div className="App">
     {/* <Counter count={count} setCount={setCount} />*/}
         <HashRouter>
                <Switch>
                    <LayoutSearch>
                        <Route exact path="/" component={SearchDividendForm}/>
                        <Route path="/search_game" component={FilterableBG}/>
                    </LayoutSearch>
                </Switch>
            </HashRouter>
    </div>
  );
}

export default App;
