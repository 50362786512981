import React from 'react';
import ProductStyle from './style/ProductStyle';
import ProductImageStyle from './style/ProductImageStyle';
import ImageStyle from './style/ImageStyle';
import ProductInfoStyle from './style/ProductInfoStyle';
/*
dividend search game block list
*/
const Product = (props)=>{
    const data = props.data;
    let style ={};
    data.pic = ".././game_pic/"+data.pic;
    if(data.pic === ".././game_pic/.jpg"){
        data.pic = ".././game_pic/noimages.png";
    }

    return(
        <ProductStyle className="product" style={{style}}>
            <ProductImageStyle className="productImage">
                <ImageStyle src= {data.pic} alt=""/>
            </ProductImageStyle>
            <ProductInfoStyle className="productInfo">
                <h6> {data.gameCName} </h6>
                <gameEName> {data.gameEName} </gameEName>
                <p>
                    <ul>
                        <li> 可租借 / 店內使用 : {data.rentStatus} </li>
                        <li> 可購買門市  : {data.storageStatus} </li>
                        <hr/>
                        <li> 價格 </li>
                        <li> 定價 : {data.originalPrice} </li>
                        <li> 會員價 : {data.memberPrice} </li>
                        <li> 玩樂卡價 : {data.vipPrice} </li>
                    </ul>
                </p>
            </ProductInfoStyle>
        </ProductStyle>
    )
}

export default Product;